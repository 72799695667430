const messages = {
  de: {
    parameters: {
      yes: 'UND',
    },
    com: {
      phase: {
        label: 'Etikett',
      },
      product: {
        ean: {
          label: 'EAN',
          helperText: '',
          helperTextPreview: '',
        },
        sku: {
          label: 'Produktcode',
          helperText: '',
          helperTextPreview: '',
        },
        manufacturer: {
          label: 'Hersteller',
          helperText: '',
          helperTextPreview: '',
        },
        pn: {
          label: 'Produktnummer',
          helperText: '',
          helperTextPreview: '',
        },
        warranty: {
          label: 'Garantie',
          months: 'Monate',
        },
      },
      totalAmount: {
        label: 'In Summe',
        helperText: '',
      },
    },
    imageGallery: {
      thumbnailsMore: '+ mehr ({number}).',
    },
    orders: {
      actions: {
        all: 'Alle Bestellungen',
      },
      myOrdersScoringBlog: {
        title: 'Meine Bestellungen',
      },
      ordersList: 'Bestellungen',
      phase: {
        1: 'Akzeptiert',
        '01-new': 'Akzeptiert',
        10: 'Offen',
        20: 'Warten auf Zahlung',
        30: 'Bezahlt',
        98: 'Gerettet',
        99: 'Abgesagt',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Paketnummer',
            phase: 'Zustand',
            paid: 'Bezahlt',
            total: 'ohne MwSt.',
            totalWithTax: 'In Summe',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'Warenkorb',
        buyButton: 'Bestellung senden',
        emptyBasket: 'Dein Einkaufswagen ist leer',
        buyThere: 'Ich habe hier schon einmal eingekauft',
        login: 'Anmeldung',
        notFill: 'Lieferinformationen sind nicht ausgefüllt',
        fill: 'Füllen Sie die Informationen aus',
        change: 'Ändern Sie die Daten',
        popupButton: 'Auf der Karte anzeigen',
        payment: {
          title: 'ZAHLUNGSART',
          free: 'Kostenlos',
          changePaymentBtn: 'ZAHLUNGSÄNDERUNG',
        },
        shipping: {
          title: 'VERSANDART',
          free: 'Kostenlos',
          changeShippingBtn: 'TRANSPORTWECHSEL',
          popup: {
            title: 'Information',
            text: 'Lieferung an Ihre Adresse per Kurier {service}',
          },
        },
      },
      actions: {
        all: 'Einkaufskörbe',
      },
      myBasketsScoringBlog: {
        title: 'Gespeicherte Warenkörbe',
      },
      cartSummaryParameters: {
        caption: 'Parameter',
      },
    },
    productDetailSpagettyNavigation: {
      description: 'Beschreibung',
      parameters: 'Parameter',
      package: 'Packungsinhalt',
      fotoAndVideo: 'Bilder und Videos',
      alternatives: 'Alternativen',
      alternativesText: 'Leider haben wir derzeit keinen Ersatz für das von Ihnen ausgewählte Produkt.',
      questions: 'Fragen',
      productDescriptionNotAvailable: 'Produktbeschreibung nicht verfügbar',
    },
    newsletter: {
      phone: '+386 1 82 82 053',
      subscribe: {
        title: 'Verpassen Sie keine Sonderangebote',
        inputText: 'Ihre E-Mail-Adresse',
        button: 'Schicken',
      },
    },
    globalNavigation: {
      home: 'Heim',
      shopping: 'Besorgen',
      orders: 'Aufträge',
      shoppingLists: 'Einkaufslisten',
      articles: 'Nachricht',
    },
    homePageWhy: {
      0: {
        title: 'Sicher einkaufen',
        content: 'Autorisiertes 3D-Secure-Zahlungssystem, das optimale Sicherheit beim Online-Einkauf garantiert.',
      },
      1: {
        title: 'Zufrieden oder zurückgegeben',
        content:
          'Sie haben das Recht, Ihre Meinung zu ändern. Profitieren Sie von unserem schnellen Rückerstattungsservice innerhalb von 14 Tagen.',
      },
      2: {
        title: 'Ware im eigenen Lager',
        content: 'Wir haben 98% aller Waren auf Lager und sind sofort versandfertig.',
      },
      3: {
        title: 'Kostenloser Transport',
        content: 'KOSTENLOSER Versand für ausgewählte Digitalkameras und Objektive.',
      },
      title: 'WARUM WIR?',
    },
    startPage: {
      title: 'Titelseite',
      gotoShopping: 'Besorgen',
    },
    shoppingCart: {
      actions: {
        buy: 'IN DEN WARENKORB',
      },
      buyBtn: 'IN DEN WARENKORB',
      alternatives: 'ALTERNATIVE',
      shipping: {
        changePoint: 'Ändern',
      },
      agreements: {
        termsRequired: 'Sie müssen den Nutzungsbedingungen zustimmen.',
      },
      validation: {
        someProductNotAvailable: 'Einige Artikel sind nicht in ausreichender Menge auf Lager.',
      },
    },
    appCartSummaryProductParameters: {
      title: 'Produktcode:',
    },
    productListPage: {
      title: 'Katalog',
      orders: {
        recommended: 'Wir empfehlen',
        name: 'Name',
        priceAsc: 'Vom höchsten Preis',
        priceDesc: 'Vom niedrigsten Preis',
      },
    },
    orderStatuses: {
      storno: 'Stornierung',
      synchronized: 'Gesendet',
      waiting: 'Warten auf Synchronisierung',
    },
    orderPreview: {
      items: 'Produkte',
    },
    cartRegisterForm: {
      title: 'Anmeldeformular',
    },
    cartPayment: {
      title: 'ZAHLUNGSART',
    },
    cartPreSummaryPage: {
      title: 'Warenkorbübersicht',
      actions: {
        nextStep: 'Ich stimme zu und fahre fort',
      },
    },
    cartCompanyForm: {
      description: 'Ergänze, wenn Sie Waren für ein Unternehmen kaufen',
    },
    cartDeliveryForm: {
      description: 'Ergänze, wenn die Lieferadresse von der Rechnungsadresse abweicht',
    },
    productAvailabilityFieldPreviewWithPopup: {
      button: 'Wann erhalte ich die bestellte Ware?',
    },
    productDetailStockInfo: {
      available: 'Wir haben das Produkt physisch in unserem Distributionslager.',
      external: 'Das Produkt ist auf dem Weg zu unserem Lager',
      notAvailable: 'Die Ware ist vorübergehend nicht verfügbar',
      labels: {
        free: 'KOSTENLOSER TRANSPORT',
        showroom: 'AUSGESTELLT IM SHOWROOM',
      },
    },
    productGifts: {
      btnName: 'GESCHENK',
      giftValue: 'Im Wert von',
    },
    homepage: {
      productOfMonth: 'Produkte des Monats',
      catalogFavorite: 'Die beliebteste Kategorie',
      hotTips: 'Heiße Tipps',
      recommended: 'Wir empfehlen',
    },
    productQuestion: {
      firstName: 'Name',
      lastName: 'Nachname',
      email: 'Email',
      subject: 'Produkt',
      question: 'Schreiben Sie eine Frage',
      gdpr: 'Mit dem Absenden einer Frage stimme ich der Verarbeitung personenbezogener Daten zum Zwecke der Beantwortung zu.',
      gdprLink: 'Link zur DSGVO',
      button: 'Senden Sie eine Frage',
      subjectContent: 'Anfrage zum Produkt: {product}, Code: {sku}',
      title: 'Sie müssen der Verarbeitung personenbezogener Daten zustimmen.',
      success: 'das Formular wurde verschickt',
    },
    lastVisitedProducts: 'Besuchte Produkte',
    productAlternativeProducts: 'Alternativen',
    productAccessoryProducts: 'Zubehör',
    productReplacementProducts: 'Alternativen',
    productAccessories: 'Zubehör',
    productReplacements: 'Auswechslungen',
    appProductDetailTabs: {
      description: 'Beschreibung',
      parameters: 'Parameter',
      package: 'Packungsinhalt',
      query: 'Anfrage',
      media: 'Bilder und Videos',
      alternatives: 'Alternativen',
      accessories: 'Zubehör',
      replacements: 'Zubehör',
      form: {
        enum: '!!!! ENUM-WERT',
      },
    },
    cartSummaryPage: {
      title: 'Zusammenfassung vor der Bestellung',
      actions: {
        continueShopping: 'mit dem Einkaufen fortfahren',
        saveOrder: 'Speichern Sie die Bestellung',
      },
      expectedDeliveryDate: {
        label: 'Gewünschtes Lieferdatum',
        helperText: '',
        helperTextPreview: '',
      },
      noteForSeller: {
        label: 'Hinweis für Spediteure',
        helperText: '',
        helperTextPreview: '',
      },
      paymentGateway: {
        label: 'Zahlungs-Gateways',
        helperText: '',
        helperTextPreview: '',
      },
    },
    userProfile: {
      title: 'Mein Konto',
      content: 'Inhalt',
      contactInfo: {
        newsletter: {
          title: 'Newsletter',
          content: 'Ich möchte Neuigkeiten erhalten',
        },
        title: 'Kontaktinformationen',
      },
      orders: {
        title: 'Bestellverlauf',
      },
      invoices: {
        title: 'Rechnungen',
      },
      myOrders: 'Aufträge',
      myInvoices: 'Rechnungen',
      logout: 'Ausloggen',
      login: 'Anmeldung',
      favorites: {
        title: 'Favorit',
      },
      changePassword: {
        title: 'Passwort ändern',
        fields: {
          login: 'Login (kann nicht geändert werden)',
          originalPassword: 'Ursprüngliches Passwort',
          newPassword: 'Neues Kennwort',
          confirmNewPassword: 'Bestätige das Passwort',
        },
        button: 'Änderungen speichern',
      },
    },
    orderSavedThankPage: {
      title: 'Vielen Dank für Ihre Bestellung {orderNumber}',
      thankMessage: {
        email: 'info@ceskyvelkoobchod.cz',
        phoneNumber: '',
        template:
          'Bitte überprüfen Sie Ihren E-Mail-Posteingang auf die Bestellbestätigung. Speichern Sie die E-Mail für Ihren eigenen Gebrauch. Alle Informationen zu Ihrer Bestellung finden Sie im folgenden Abschnitt.',
      },
      order: 'Bestellnummer:',
      payment: 'Bezahlung des Tages:',
      cancelled: 'Abgesagt',
      accepted: 'Akzeptiert',
      pending: 'Warten',
      paid: 'Bezahlt',
      actions: {
        back: 'Zurück zur Hauptseite',
        detail: 'Bestellübersicht',
        continue: 'mit dem Einkaufen fortfahren',
      },
      decline: 'Abgelehnt',
    },
    shoppingLists: {
      title: 'Einkaufsliste',
      actions: {
        saveAsShoppingList: 'Einkaufsliste speichern',
        importShoppingListToCart: 'Importieren',
      },
    },
    searchResultPage: {
      search: 'Suchbegriff',
      results: 'Suchergebnisse',
    },
    homePageArticles: {
      title: 'Nachricht',
      button: 'Alle Artikel anzeigen',
    },
    loginPage: {
      title: 'Anmeldung',
      forgotPassword: 'Passwort zurücksetzen',
      email: 'Per Email',
      sendEmail: 'Eine E-Mail senden',
      info: 'Sie erhalten eine E-Mail mit einem Link zum Ändern Ihres Passworts',
      password: 'Passwort',
      notSame: 'Passwort stimmt nicht überein',
      newPassword: 'Neues Kennwort',
      confirmPassword: 'Bestätige das Passwort',
      changePassword: 'Passwort ändern',
    },
    productPrice: {
      withoutTax: 'ohne MwSt.',
    },
    productDetailPage: {
      availability: {
        dateRange: 'Bestellen Sie {today} und wir versenden innerhalb von {minDays}-{maxDays}.',
        today: 'Bestellen Sie heute bis {time} und wir versenden noch heute.',
        todayWord: 'Heute',
        tomorrow: 'Bestellen Sie {today} und wir versenden morgen.',
        nextDateTime: '13:30',
        dayOfWeek: {
          message: 'Bestellen Sie {today} und wir liefern es {day}.',
          1: 'Montag',
          2: 'Dienstagen',
          3: 'Mittwoch',
          4: 'Donnerstag',
          5: 'Freitag',
          6: 'Samstag',
          0: 'Sonntag',
        },
      },
      allDescription: 'PRODUKTBESCHREIBUNG',
      demoProduct: {
        text: 'KAUFEN SIE GÜNSTIGER für:',
      },
      productNote: {
        title: '???',
        note: 'Hinweis 2 - Zusätzliche Zustandsbeschreibung oder ähnliche Produktinformationen, warum gibt es einen rabatt und was ist auf dem produkt...',
      },
      adornments: {
        notForSale: 'Nicht zu verkaufen',
        usedProduct: 'Gebraucht',
        freeGift: 'Geschenk kostenlos',
        freeShipping: 'Kostenloser Transport',
        eol: 'Verkauf beendet',
        demo: 'Demoprodukt',
        sale: 'Rabatt',
      },
      disponibility: {
        primaryLessThan: 'Weniger als {count} Einheiten AUF LAGER',
        primaryMoreThan: 'AUF LAGER weniger als {count, plural, zero {ein Stück} other {{count} Stück}}',
        secondaryLessThan: 'AUF LAGER im Vorbereitungslager weniger als {count} Stück',
        secondaryMoreThan:
          'AUF LAGER in Vorbereitungslager {count, plural, zero {ein Stück} other {{count} und mehrere Stücke}}',
        external: 'Das Produkt ist auf dem Weg zu unserem Lager',
        unavailable: 'Das Produkt ist nicht verfügbar',
        showroom: 'Ausgestellt im Showroom',
        soldout: 'Das Produkt ist ausverkauft',
        'on-way': {
          d: '',
          m: 'Unterwegs für {from, plural, one {Minute} two {Minute} other {Minute}}',
          M: 'Unterwegs {von, Plural, ein {Monat} zwei {Monate} wenige {Monate} otherch {Monate}}',
          dRange: 'Unterwegs {{von}-{bis} Tagen} oder {{von}-{bis} Tagen}}',
          mRange: 'Auf dem Weg {von}-{nach, Plural, ein {Tag} ein paar {Tage} other {Tage}}',
          MRange: 'Auf dem Weg {from}-{to, plural, one {Monat} two {Monate} few {Monate} other {Monate}}',
          date: '{datum}',
        },
        time: 'Unterwegs für {count, plural, zero {ein Stück} other {{count} und weitere Stücke}}',
      },
    },
    comparePage: {
      title: 'Vergleichen',
      removeButton: 'Aus Vergleich entfernen',
      nothing: 'Keine Produkte zum Vergleichen',
    },
    filter: {
      reset: 'Setzen Sie den Filter zurück',
      search: 'Suchen',
    },
    orderTermsAndConditions: {
      select: {
        label: 'Bestellbedingungen',
        helperText: '',
        helperTextPreview: '',
      },
    },
    orderDetailPage: {
      actions: {
        import: 'in den Warenkorb',
      },
    },
    invoiceBlogPreview: {
      title: 'Meine Accounts',
      button: 'Alle Konten',
    },
    userProfilePage: {
      logout: 'Ausloggen',
      setting: 'Einstellungen',
      passwordChange: 'Passwort ändern',
    },
    appBarActionRegion: {
      changeRegion: 'Wählen Sie eine Sprache',
      label: 'OK',
    },
    subCategories: {
      title: 'Unterkategorie',
    },
    aboutShoppingPage: {
      title: 'Alles über den Einkauf',
      contact: 'Kontakt',
      electronicClosureOfRevenue: 'Elektronische Einkommensnachweise',
      informationDuty: 'Datenschutzerklärung',
      processingOfPersonalData: 'Datenschutz',
      cashback: 'Cashback',
      delivery: 'Lieferung',
      availability: 'Warenverfügbarkeit',
      methodOfPayment: 'Zahlungsarten',
      termsAndConditions: 'AGB',
      termsOfAppeal: 'Reklamationsbedingungen',
      contractwWithdrawal: 'Widerrufsrecht',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Ihre Steuernummer wurde überprüft, die Preise werden ohne Mehrwertsteuer berechnet.',
        viesNotVerified:
          'Ihre Steuernummer wurde nicht verifiziert oder Sie erfüllen nicht die Voraussetzungen für einen umsatzsteuerfreien Einkauf.',
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'PERSÖNLICHE DATEN',
      pleaseComplete: 'FÜLLEN',
      closeButton: 'Schließen',
    },
    cartPromoCodes: {
      title: 'PROMO-CODE ANWENDEN',
      textFieldPlaceholder: 'PROMO-CODE',
      textFieldHelperText: 'Es ist möglich, nur einen Promo-Code pro Bestellung einzulösen',
      applyPromoCodeButton: 'ANWENDEN',
    },
    cookiesBar: {
      agreeBtn: 'OK',
      title: 'HINWEIS ZU COOKIES AUF UNSERER WEBSITE',
      text: 'Unser Unternehmen verwendet auf dieser Website eigene Cookies (sogenannte Basis-Cookies) und Cookies von Drittanbietern. Basierend auf diesen Dateien und Interessen passen wir die Website an die Kundenerwartungen an, optimieren unsere Angebote und beugen Betrugsrisiken vor. Ohne einige notwendige Cookies kann die Website nicht richtig funktionieren. Indem Sie auf die Schaltfläche „Alle akzeptieren“ klicken, stimmen Sie deren Verwendung zu, einschließlich Informationen über das Verhalten der Website. Sie können Ihre Einwilligung jederzeit widerrufen.',
    },
    cartFormLogin: {
      btnSend: 'Schicken',
      button: 'Anmeldung',
      title: 'Anmeldung',
      forgottenPasswordBtn: 'Haben Sie das Passwort vergessen?',
      text1: 'Wir senden Ihnen ein Einmalpasswort an die von Ihnen angegebene E-Mail-Adresse.',
      text2: 'Legen Sie nach dem Einloggen ein neues Passwort fest.',
      error: 'Benutzername oder Passwort falsch.',
    },
    cartSummartSection: {
      title: 'BESTELLÜBERSICHT',
    },
    appCartSummarySubjectsList: {
      title: 'PRODUKT',
      text: 'BESCHREIBUNG',
    },
    newsletterText: {
      text1: 'Brauchen Sie Rat?',
      text2: 'Sie erreichen uns werktags von 8.00 bis 16.00 Uhr.',
    },
    cartSummarySection: {
      labelWithoutVAT: '(ohne MwSt.)',
      priceLabel: 'EINZELPREIS',
      amount: 'MENGE',
      totalPriceLabel: 'GESAMT',
      totalPrice: 'In Summe',
      submitButton: 'BESTELLUNG SENDEN',
    },
    cartSummarySubjectsListItemDirectPreview: {
      amountTitle: '',
    },
    cartSummaryListItem: {
      freeTitle: 'Kostenlos',
      amountTitle: 'Stck',
    },
    sxCartNotes: {
      note: 'Sie dient nicht der Angabe des Liefertermins',
      addNote: 'Füg ein Notiz hinzu',
    },
    productDetail: {
      promoCode: {
        title: 'mit dem Promo-Code:',
        validTo: 'gültig bis:',
      },
      productSendLink: {
        copyLinkBtn: 'Kopieren Sie den Link',
        sendLinkByEmail: 'Mailen Sie den Link',
      },
      productDetailBarInformation: {
        addToFavourites: 'Zu den Favoriten hinzufügen',
        compareProduct: 'Vergleichen Sie das Produkt',
        sendLink: 'Senden Sie einen Link',
        serviceList: 'ALLE SERVICE',
        questions: 'Fragen',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: 'KAUF AUF RECHNUNG',
        otherDeliveryInfo: 'LIEFERUNG AN ANDERE ADRESSE',
        otherDeliveryInfoAlert: 'Der Mehrwertsteuerzahler kann keine other Adresse für die Lieferung verwenden.',
      },
      companyNameLabel: 'Name der Firma',
      firstNameLabel: 'Name',
      lastNameLabel: 'Nachname',
      streetLabel: 'Straße',
      cityLabel: 'Stadt',
      zipLabel: 'PLZ',
      companyIDLabel: 'Steuer Nr.',
      countryLabel: 'Zustand',
    },
    cartFormPersonal: {
      label: 'Postanschrift',
      firstNameLabel: 'Name',
      lastNameLabel: 'Nachname',
      streetLabel: 'Straße und Hausnummer',
      pscLabel: 'PLZ',
      cityLabel: 'Stadt',
      countryLabel: 'Zustand',
      phoneLabel: 'Telefonnummer',
      emailLabel: 'E-Mail-Adresse',
      passwordLabel: 'Passwort',
      controlPasswordLabel: 'Passwort kontrollieren',
    },
    cartFormInvoice: {
      labelDesktop: 'ZAHLERDATEN / (Daten werden auf die Rechnung gedruckt)',
      labelMobile: 'Kontaktinformationen',
      companyLabel: 'Firma',
      password: 'Passwort',
      confirmPassword: 'Kennwortprüfung',
      email: 'E-Mail-Adresse',
      firstName: 'Name',
      lastName: 'Nachname',
      countryLabel: 'Zustand',
      phone: 'Telefonnummer',
      phoneError: 'Die Telefonnummer ist nicht korrekt',
      streetLabel: 'Straße',
      streetNumberLabel: 'Hausnummer',
      zipLabel: 'PLZ',
      icoLabel: 'Steuer Nr.',
      dicLabel: 'Ust-IDnr.',
      country: 'Zustand',
      cityLabel: 'Stadt',
      registration: 'Ich möchte ein Konto eröffnen',
      registrationNote: 'Eröffnen Sie ein Konto und Sie können Ihre Bestellungen verfolgen, Sendungen und Zahlungen.',
      button: 'Änderungen speichern',
    },
    page404: {
      title: 'Leider existiert die Seite nicht...',
      subTitle: 'Weiter zur Hauptseite.',
      button: 'Zurück zur Hauptseite.',
    },
    productListOrderSelectorButton: {
      selectLabel: 'Sortiert nach:',
      byName: 'Name',
      byPrice: 'Preis',
      onlyStock: 'Nur auf Lager',
      resetFilter: 'Filter aufheben',
      displayCountOfProducts: 'Produkte',
      search: 'Suche',
      clear: 'Löschen',
      ok: 'Filter',
    },
    priceRangeSliderField: {
      title: 'Preisklasse',
    },
    productListMultipleFilterMobileDialog: {
      headerTitle: 'Filtern nach',
    },
    productListMultipleFilterMobile: {
      title: 'FILTER',
    },
    cartCompany: {
      companyName: 'Firmenname',
      regNo: 'Steuer Nr.',
      taxNo: 'Ust-IDnr.',
      taxNoPlaceholder: 'ATU00000000',
      warningMessage: {
        warning: 'Wichtig.',
        message:
          'Bei Lieferung an eine other Adresse als die Firmenadresse unterliegt die Bestellung der Mehrwertsteuer.',
      },
    },
    searchResultsPage: {
      match: 'Wir haben {resultCountFormatted} Ergebnisse für {searchText} gefunden.',
      nomatch: 'Keine Ergebnisse gefunden für {searchText}.',
      matchingCategories: 'Kategorien gefunden',
      button: 'Zurück zur Hauptseite.',
    },
    selectMultipleFilter: {
      totalChipsLabel: 'Anzahl ausgewählter Produkte: {total}',
    },
    cartDelivery: {
      firstName: 'Name',
      lastName: 'Nachname',
      companyName: 'Firmenname',
      streetLabel: 'Straße',
      streetNumberLabel: 'Hausnummer',
      zipLabel: 'PLZ',
      country: 'Zustand',
      cityLabel: 'Stadt',
    },
    cartNotes: {
      shippingLabel: 'Fügen Sie dem Spediteur eine Notiz hinzu',
      shippingNote: 'Hinweis für Spediteure',
      sellerLabel: 'Hinweis zum Verkäufer hinzufügen',
      sellerNote: 'Hinweis für Verkäufer',
      loginNote:
        'Wenn Sie bereits ein Konto bei uns haben, wird das Formular bei der Registrierung automatisch ausgefüllt.',
    },
    cartCountryShipping: {
      title: 'LIEFERLAND',
      label: 'Die Wahl des Landes kann sich auf die Zahlung, den Versand und das Lieferdatum auswirken',
    },
    cartAddress: {
      bllingAddress: 'Rechnungsadresse',
      deliveryAddress: 'Postanschrift',
      pickUpPointAddress: 'Adresse des Abholpunkts',
    },
    cartAgreements: {
      customerVerified:
        'Ich stimme der Zusendung des Fragebogens nicht zu, der Teil des Programms „Verifiziert durch Kunden“ ist, das zum Zweck der Messung der Kundenzufriedenheit und zum Zweck der Verbesserung der Qualität unserer Dienstleistungen durchgeführt wird.',
    },
    loginDialog: {
      username: 'Nutzername',
      password: 'Passwort',
    },
    sprinxFormsValidation: {
      confirmPassword: 'Bestätige das Passwort',
      invalidPostalCode: 'Die eingegebene Postleitzahl hat kein gültiges Format',
      usernameIsNotFree: 'Ein Benutzer mit diesem Namen ist bereits registriert.',
      emailEmpty: 'E-Mail ist richtig!',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Fügen Sie ein neues Konto hinzu',
        all: 'Alle Konten',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'Pdf',
            number: 'Nummer',
            invoiceDate: 'Rechnungsdatum',
            dueDate: 'Tag',
            total: 'ohne MwSt.',
            totalWithTax: 'In Summe',
            billingAddress: 'Rechnungsadresse',
            orderNumber: 'Bestellnummer',
            unpaidAmount: 'Unbezahlter Betrag',
          },
          filter: {
            number: 'Nummer',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Kontolinien',
          columns: {
            priceUnit: 'Preis',
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: 'Gesamtmenge',
      totalPriceWithTax: 'Gesamtmenge',
      tax: 'MwSt',
      withoutTax: 'Gesamtpreis ohne Mehrwertsteuer',
    },
    compare: {
      addToCompare: 'Das Produkt wurde zum Vergleich hinzugefügt',
      removeToCompare: 'Das Produkt wurde aus dem Vergleich entfernt',
      price: 'Preis',
      ean: 'EAN',
    },
    favorite: {
      addToFavorite: 'Das Produkt wurde zu den Favoriten hinzugefügt',
      removeFromFavorite: 'Das Produkt wurde aus den Favoriten entfernt',
    },
    registration: {
      important: 'WICHTIG!',
      note: 'Wir senden Ihnen ein Einmalpasswort per E-Mail zu. Ändern Sie nach dem Einloggen Ihr Passwort.',
      gdprAgreement: 'Sie müssen der Verarbeitung personenbezogener Daten zustimmen',
      submitButton: 'Registrieren',
      fail: 'Die Registrierung war nicht erfolgreich, bitte versuchen Sie es später erneut',
      success: 'Die Registrierung war erfolgreich',
      title: 'Anmeldung',
      dialog: 'Wenn Sie noch kein Konto bei uns haben, können Sie sich registrieren',
      here: 'Hier',
    },
    appBarActionGlobalSearchComposed: {
      label: 'Produktsuche',
    },
    appBarActions: {
      about: 'Alles über den Einkauf',
      aboutHref: '/alles-uber-den-einkauf',
      compare: 'Vergleichen',
    },
    appBarActionUser: {
      title: 'Anmeldung',
    },
    footer: {
      copyright: 'COPYRIGHT © ČESKÝ VELKOOBCHOD S.R.O. - Alle Rechte vorbehalten',
    },
    appArticlesPage: {
      title: 'Artikel',
    },
    errorPage: {
      title: 'Irgendwas stimmt nicht.',
      button: 'Hauptseite',
    },
    cartSummary: {
      withoutTax: 'ohne MwSt.',
    },
    renewPasswordForm: {
      success: 'Ein neues Passwort wurde an Ihre E-Mail-Adresse gesendet.',
      emailNotFound: 'E-Mail nicht gefunden!',
      error: 'Irgendwas stimmt nicht',
    },
    cartSubjectListItemQuantityWarning: 'Die maximale Bestellmenge beträgt {count} Produkte',
  },
};
export default messages;
