// import loadable from '@loadable/component';
// import ComponentLoader from './AppComponentLoader';
import CldrLocaleDe from '@sprinx/react-globalize/CldrLocaleDe';
// @ts-ignore
import GlobalizeProvider from '@sprinx/react-globalize/GlobalizeProvider';
import React from 'react';
import MessagesAt from './i18n/MessagesAt';

export interface AppGlobalizeProps {
  children: React.ReactNode;

  /**
   * Defaultni jazyk.
   */
  // defaultLocale?: string;

  // /**
  //  * Handler zmeny preferovaneho jazuku.
  //  *
  //  * Signatura: `(languageCode) => void`
  //  */
  // onPreferredLanguageChanged?: (languageCode: string) => void;

  // supportedLocales: string[] | { code: string }[];
}
const defaultLocale = 'de';
const supportedLocales = ['de'];

const AppGlobalize: React.FC<AppGlobalizeProps> = ({
  children,
  // defaultLocale = 'en',
  // onPreferredLanguageChanged,
  // supportedLocales,
}) => {
  return (
    <GlobalizeProvider
      cldrs={{ de: CldrLocaleDe }}
      messages={{ de: MessagesAt }}
      defaultLocale={defaultLocale}
      supportedLocales={supportedLocales}
      // onPreferredLanguageChanged={onPreferredLanguageChanged}
      dontResolveLocale
      // defferInitialization={onAuth}
      // cache={cache}
      // loadSetting={loadLanguageSetting}
      // onPreferredLanguageChanged={onPreferredLanguageChanged}
    >
      {children}
    </GlobalizeProvider>
  );
};

export default AppGlobalize;
